import $ from 'jquery'
(() => {
  /* USAGE:
    <a class="js-accordion" data-detail-selector=".detail1">
      HEAD
    </a>
    <section class="detail1" style="display: none">
      body
    </section>
  */
  $('.js-accordion').on('click', (e) => {
    const $el = $(e.currentTarget)
    const selector = $el.attr('data-detail-selector')
    const $target = $(selector)
    $el.toggleClass('open')
    $target.slideToggle()
  })
})()
