import $ from 'jquery'
import modal from './modal'
import {playAudio} from './sound'
import { throttle } from './throttle'

export default class Votes {
  selectedMembers = {}
  constructor() { }

  initialize() {
    this.$el = $('.js-vote')
    if(this.$el.length <= 0) return

    this.isEvent = this.$el.attr('mode') === 'event'
    this.voteNum = this.isEvent ? 1 : 7
    this.updateSubmitBtn()

    this.$selectedPanel = $('.js-selected-panel')
    this.$selectedPanelShownTarget = $('.js-selected-panel-shown-target')
    this.$selectedPanelFooter = $('.js-selected-panel-footer')

    const onScroll = throttle((e) => this.onScroll(e), 50)
    window.addEventListener('scroll', onScroll)

    this.$el.find('.js-vote-checkbox').on('click', (e) => {
      const el = $(e.currentTarget)
      const color = this.isEvent ? 'event' : el.attr('color')
      const memberId = el.attr('member-id')
      if(this.isEvent) {
        this.resetSelectedAll()
      } else {
        this.resetSelectedColor(color)
      }
      el.addClass('votes-Selector_CharacterCheckbox-checked')
      $(`.js-profile-modal-content[member-id="${memberId}"]`).addClass('checked')
      this.selectedMembers[color] = memberId
      const $selected = $(`.js-selected[color=${color}]`)
      $selected.removeClass((i, className) => (className.match(/\bvotes-SelectedPanel_CharacterThumb-\S+/g) || []).join(' '))
      $selected.addClass('votes-SelectedPanel_CharacterThumb-selected')
      $selected.addClass(`votes-SelectedPanel_CharacterThumb-${el.attr('color')}`)

      $selected.find('.js-selected-thumb').attr('src',el.attr('selected-img-src'))
      this.updateSubmitBtn()
    })

    this.$el.find('.js-selected').on('click', (e) => {
      const el = $(e.currentTarget)
      const color = el.attr('color')
      el.removeClass((i, className) => (className.match(/\bvotes-SelectedPanel_CharacterThumb-\S+/g) || []).join(' '))
      if(this.isEvent) {
        this.resetSelectedAll()
      } else {
        this.resetSelectedColor(color)
      }
      this.selectedMembers[color] = null
      this.updateSubmitBtn()
    })

    $('.js-vote-submit').on('click', (e) => {
      const ids = this.selectedIds()
      if(ids.length >= this.voteNum) {

        const token = $('meta[name="csrf-token"]').attr('content');

        $.ajax({
          type: 'POST',
          url: '/votes',
          dataType : 'json',
          headers: {
            'X-CSRF-Token': token
          },
          data: {
            member_ids: ids
          }
        }).done((d) => {
          const html = $('.js-completed-modal').html()
          modal.show(html)
          this.$el.addClass('votesIndex-completed')
          // TODO: SNSシェア
        }).fail((e) => {
          window.alert('投稿に失敗しました。\r時間を置いて再度お試しください')
        })
      }
    })

    $('.js-profile-btn').on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const $btn = $(e.currentTarget)
      const modalId = $btn.attr('data-member-id')
      const contents = $($(`.js-profile-modal[member-id=${modalId}]`).html())
      contents.find('.js-play-voice').on('click', (e) => {
        const el = $(e.currentTarget)
        const soundPath = el.attr('data-src')
        playAudio(soundPath)
      })
      modal.show(contents, 'modal-plate modal-narrow')
    })
  }

  resetSelectedAll() {
    $(`.js-vote-checkbox`).removeClass('votes-Selector_CharacterCheckbox-checked')
    $(`.js-profile-modal-content`).removeClass('checked')
  }

  resetSelectedColor(color) {
    $(`.js-vote-checkbox[color="${color}"]`).removeClass('votes-Selector_CharacterCheckbox-checked')
    $(`.js-profile-modal-content[color="${color}"]`).removeClass('checked')
  }
  selectedIds() {
    return Object.keys(this.selectedMembers).map(m => this.selectedMembers[m]).filter((m) => m)
  }

  updateSubmitBtn() {
    const ids = this.selectedIds()
    const $submit = $('.js-vote-submit')
    if(ids.length >= this.voteNum) {
      $submit.removeClass('votes-SelectedPanel_CharacterSubmitBtn-disabled')
    } else {
      $submit.addClass('votes-SelectedPanel_CharacterSubmitBtn-disabled')
    }
  }

  onScroll() {
    if(this.$selectedPanel.length <= 0) return
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const top = this.$selectedPanelShownTarget.get()[0].getBoundingClientRect().top
    const footer = this.$selectedPanelFooter.get()[0].getBoundingClientRect().bottom

    if((windowHeight / 4) * 3 > top) {
      this.$selectedPanel.addClass('shown')
    } else {
      this.$selectedPanel.removeClass('shown')
    }
    if(windowHeight > footer) {
      this.$selectedPanel.addClass('sticky')
    } else {
      this.$selectedPanel.removeClass('sticky')
    }
  }
}
