import $ from 'jquery'

export default class Loader {
  constructor() { }

  initialize() {
    this.$el = $('.js-loader')
    return this
  }

  hide() {
    this.$el.fadeOut()
  }

  show() {
    this.$el.fadeIn()
  }
}
