import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default class Hamburger {
  constructor() { }

  initialize() {
    this.isOpen = false
    this.$toggle = $('.js-drawer-toggle')
    this.$nav = $('.js-drawer-nav')
    this.$toggle.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
    })
    this.$nav.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.toggle()
    })
    $('.js-drawer').on('click', (e) => {
      e.stopPropagation()
    })
    return this
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.$toggle.toggleClass('drawer-open')
    this.$nav.toggleClass('drawer-open')
    if(this.isOpen) {
      disableBodyScroll(document.querySelector('.js-drawer-nav'))
    } else {
      clearAllBodyScrollLocks()
    }
  }
}
