export const throttle = function(callback, interval = 300) {
  let time = Date.now(),
      lag,
      debounceTimer,
      debounceDelay = 16;

  return (e) => {
    lag = time + interval - Date.now();
    if (lag < 0) {
      callback(e);
      time = Date.now();
    } else {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(function() {
        callback(e);
      }, (interval - lag + debounceDelay));
    }
  }
};
