import $ from 'jquery'
import { throttle } from './throttle'

let $el
function _onScroll(e) {
  const scrollY = window.scrollY
  $el.each((i, d) => {
    const $d = $(d)
    const speed = $d.attr('speed')
    $d.css({
      'background-position-y': (window.scrollY * speed) + 'px'
    })
  })
}

export const parallax = () => {
  $el = $('.js-parallax')
  if($el.length <= 0) return
  const onScroll = throttle((e) => _onScroll(e), 50)
  window.addEventListener('scroll', onScroll)
}
