import $ from 'jquery'

export const scrollLoader = () => {
  const $list = $('.js-scroll-loader-list')
  const $more = $('.js-scroll-loader-more')
  let page = 2
  let isLoading = false
  $more.on('click', () => {
    if(isLoading) return
    isLoading = true
    $more.addClass('loading')
    const url = $more.attr('url')
    $.ajax({
      type: 'GET',
      url: url + '?page=' + page,
      dataType : 'json',
    }).done((d) => {
      $more.removeClass('loading')
      isLoading = false
      ++page
      const loadedArticles = d.news.map((article) => {
        return `
          <li class="halfAnniversary-NewsCell">
            <a href="/news/${article.formatted_id}" class="halfAnniversary-NewsCell_Link">
              <time class="halfAnniversary-NewsCell_Date">${article.formatted_publish_date}</time>
              <h3 class="halfAnniversary-NewsCell_Title">
                <span class="halfAnniversary-NewsCell_TitleInner">
                  ${article.title}
                </span>
              </h3>
              <p class="halfAnniversary-NewsCell_Body"> ${article.short_body}</p>
              <p class="halfAnniversary-NewsCell_More">
                <span class="halfAnniversary-NewsCell_MoreIcon">
                  <span class="material-icons">
                    chevron_right
                  </span>
                </span>
                続きはこちらから
              </p>
            </a>
          </li>
        `
      })

      $list.append(loadedArticles)
      if(d.total_pages < page) {
        $more.addClass('hidden')
      }
    }).fail((e) => {
      $more.removeClass('loading')
      alert('読み込みに失敗しました。\r時間を置いて再度お試しください')
      isLoading = false
    })
  })
}
