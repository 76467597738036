import $ from 'jquery'
import Charts from './charts'

const renderShareUrl = (result, name, url) => {
  return `https://twitter.com/intent/tweet?text=${name}さんにおすすめのデートプランは…「${result.name}」の「${result.description}」でした！あなたもやってみよう！&url=${url}&hashtags=ビバレン,ビバレンXmasデート`
}

export default class Xmas2023 {
  constructor() { }

  initialize() {
    this.$el = $('.js-xmas2023')
    if(this.$el.length <= 0) return
    const charts = new Charts()
    charts.initialize(data, renderShareUrl)
  }

  renderShareUrl
}

const data = {
  questions: [
    [
      {
        title: 'あなたが森を歩いていると、目の前に三つの道がありました。どの道を選びますか？',
        answers: [
          {
            text: '木々が生い茂る森の道',
            score: 1
          },
          {
            text: '小川が流れる石畳の道',
            score: 2
          },
          {
            text: '広々とした草原の道',
            score: 4
          }
        ]
      },
      {
        title: 'あなたがペットにしたい動物はどれですか？',
        answers: [
          {
            text: '自由奔放な子豚',
            score: 1
          },
          {
            text: '賢い鷹',
            score: 2
          },
          {
            text: '忠実な犬',
            score: 4
          }
        ]
      },
      {
        title: '今食べたいものは？',
        answers: [
          {
            text: '寿司',
            score: 1
          },
          {
            text: 'ラーメン',
            score: 2
          },
          {
            text: 'ピザ',
            score: 4
          }
        ]
      },
    ], [
      {
        title: 'あなたは夢を見ています。それはどんな夢？',
        answers: [
          {
            text: '空を自由に飛ぶ夢',
            score: 8
          },
          {
            text: '待ち合わせに遅刻する夢',
            score: 16
          },
          {
            text: '好物をお腹いっぱい食べる夢',
            score: 32
          }
        ]
      },
      {
        title: '雪が降ってきた！どうする？',
        answers: [
          {
            text: '雪だるまつくろー！',
            score: 8
          },
          {
            text: '家の中でぬくぬく',
            score: 16
          },
          {
            text: '食べる',
            score: 32
          }
        ]
      },
      {
        title: '遠くで手を振っている人がいます。それは誰？',
        answers: [
          {
            text: '親しい友人',
            score: 8
          },
          {
            text: '家族',
            score: 16
          },
          {
            text: '恋人',
            score: 32
          }
        ]
      },
    ], [
      {
        title: '小さな子供が泣いている！どうする？',
        answers: [
          {
            text: '泣き止むのを待つ',
            score: 64
          },
          {
            text: '面白いことをして笑わせる',
            score: 128
          },
          {
            text: '誰か助けを呼ぶ',
            score: 256
          }
        ]
      },
      {
        title: '大富豪から3億円譲り受けた！どうする？',
        answers: [
          {
            text: '貯金する',
            score: 64
          },
          {
            text: '豪遊する',
            score: 128
          },
          {
            text: '投資する',
            score: 256
          }
        ]
      },
      {
        title: '大切な人と喧嘩をしてしまったあなた。どうする？',
        answers: [
          {
            text: '自分から謝って仲直りする',
            score: 64
          },
          {
            text: '時が解決するのを待つ',
            score: 128
          },
          {
            text: '相手が謝るまで許してあげない！',
            score: 256
          }
        ]
      },
    ]
  ],
  results: [
    {
      scores: [73,138],
      image_url: '',
      name: 'TAIYO',
      description: '二人でいればあったかい！遊園地遊びつくしデート',
      key: 'taiyo'
    }, {
      scores: [76,84],
      image_url: '',
      name: 'SUBARU',
      description: '冬の星座を探しに行く。天体観測デート',
      key: 'subaru'
    }, {
      scores: [161,266],
      image_url: '',
      name: 'ISSEI',
      description: '箱根でゆったり日帰り温泉デート',
      key: 'issei'
    }, {
      scores: [82,74],
      image_url: '',
      name: 'MIO',
      description: '水族館で大人背伸びのロマンチックデート',
      key: 'mio'
    }, {
      scores: [164,265],
      image_url: '',
      name: 'JINTARO',
      description: '雪山でウィンタースポーツデート',
      key: 'jintaro'
    }, {
      scores: [81,268],
      image_url: '',
      name: 'CION',
      description: '美しい作品に触れる展覧会デート',
      key: 'cion'
    }, {
      scores: [140,289],
      image_url: '',
      name: 'NAGOMU',
      description: '手作りスノードームとクルージングデート',
      key: 'nagomu'
    }, {
      scores: [137,146],
      image_url: '',
      name: 'CUC',
      description: '自然の中で遊んで食べてグランピングデート',
      key: 'cuc'
    }, {
      scores: [148,274],
      image_url: '',
      name: '39YEAH↗',
      description: 'ハウススタジオで一緒に料理デート',
      key: 'sakuya'
    }, {
      scores: [98,290],
      image_url: '',
      name: 'REY',
      description: 'ゴージャスビューティーデート',
      key: 'rey'
    }, {
      scores: [162,292],
      image_url: '',
      name: 'AUGURI',
      description: 'お揃いショッピングスナップデート',
      key: 'auguri'
    }, {
      scores: [100,276],
      image_url: '',
      name: 'FUTABA',
      description: 'もふもふふれあいデート',
      key: 'futaba'
    }, {
      scores: [145,273],
      image_url: '',
      name: 'LION',
      description: '舞台鑑賞夜の深い時間デート',
      key: 'lion'
    }, {
      scores: [97],
      image_url: '',
      name: 'KAZU',
      description: 'ちょっと背伸び大人の映画館デート',
      key: 'kazu'
    },
  ]
}
