import {Howl} from 'howler'

let _audio
export function playAudio(soundPath, onPlayEnd = () => {}) {
  if(_audio && typeof _audio.stop === 'function') {
    _audio.stop()
  }

  const audio = new Howl({
    src: [soundPath],
    loop: false,
    volume: 0.75,
    onend: onPlayEnd
  })
  audio.play()
  _audio = audio
}
