import $ from 'jquery'

export default class {
  constructor() { }

  initialize() {
    this.$el = $('.js-copy-btn')
    this.$el.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const text = this.$el.attr('data-copy-text')
      if(this.copy(text)) {
        window.alert('コピーしました')
      }
    })
    return this
  }

  copy(text) {
    const tmp = document.createElement('div')
    const pre = document.createElement('pre')

    pre.style.webkitUserSelect = 'auto'
    pre.style.userSelect = 'auto'
    pre.style.opacity = '0'

    tmp.style.position = 'fixed'
    tmp.style.top = '-2000%'

    tmp.appendChild(pre).textContent = text

    document.body.appendChild(tmp)
    const selection = document.getSelection()
    if (!selection) return false
    selection.selectAllChildren(tmp)

    const result = document.execCommand('copy')

    document.body.removeChild(tmp)

    return result
  }

}
