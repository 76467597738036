import $ from 'jquery'
import modal from './modal'
export default class Members {
  constructor() { }

  initialize() {
    this.$el = $('.js-member-show')
    if(this.$el.length <= 0) return
    $('.js-member-zoom-btn').on('click', (e) => {
      const $target = $(e.currentTarget)
      if(this.$el.hasClass('member-Show-zoomIn')) {
        this.$el.removeClass('member-Show-zoomIn')
        this.$el.addClass('member-Show-zoomOut')
      } else {
        this.$el.removeClass('member-Show-zoomOut')
        this.$el.addClass('member-Show-zoomIn')
      }
    })
  }
}
