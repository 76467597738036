import $ from 'jquery'
import modal from './modal'

class Counter {
  initialize($el, onUpdated, incrementable) {
    this.$el = $el
    if(this.$el.length <= 0) return
    this.memberId = $el.attr('member-id')
    this.oponentId = $el.attr('oponent-id')
    this.onUpdated = onUpdated
    this.incrementable = incrementable
    this.count = 0
    this.$counter = this.$el.find('.js-vote-counter-num')
    this.$decrementBtn = this.$el.find('.js-vote-decrement-btn')
    this.$incrementBtn = this.$el.find('.js-vote-increment-btn')
    this.$decrementBtn.on('click',() => this.decrement())
    this.$incrementBtn.on('click',() => this.increment())

    this.update()
    return this
  }

  update() {
    this.onUpdated(this)
    this.render()
  }

  increment() {
    if(!this.incrementable()) return
    ++this.count
    this.update()
  }

  decrement() {
    if(this.count <= 0) return
    --this.count
    this.update()
  }

  render() {
    this.$counter.text(this.count)
  }
}

export default class PlatinumGlanz {
  constructor() { }

  initialize() {
    this.$el = $('.js-platinum-glanz')
    if(this.$el.length <= 0) return
    this.postData = {
      serial_code: '',
      vote_schedule_id: $('#vote-schedule-id').val(),
      votes: []
    }
    this.isSubmitting = false
    this.MAX_TOTAL_COUNT = Math.trunc(this.$el.attr('max-total-count'))
    this.$submitBtn = this.$el.find('.js-vote-submit')
    this.$submitBtn.on('click', (e) => this.submit(e))
    this.$errorLabel = this.$el.find('.js-error-area')
    this.$totalLabel = this.$el.find('.js-vote-total-count')
    const $counters = this.$el.find('.js-vote-counter')
    this.totalCount = 0
    this.counters = $counters.map((i, el) => {
      return new Counter().initialize(
        $(el),
        (memberId,count) => this.onUpdated(memberId, count),
        () => this.totalCount < this.MAX_TOTAL_COUNT
      )
    })
  }

  onUpdated(counter) {
    const memberId = counter.memberId
    const oponentId = counter.oponentId
    const count = counter.count
    const vote = this.postData.votes.find((v) => v.member_id === memberId)
    if (vote) {
      vote.quantity = count
    } else {
      this.postData.votes.push({member_id: memberId, quantity: count})
    }

    this.postData.votes = this.postData.votes.filter((v) => v.quantity !== 0)
    this.totalCount = this.postData.votes.reduce((acc, v) => acc + v.quantity, 0)

    this.$totalLabel.text(this.totalCount)

    if(count > 0) {
      this.$el.find(`[member-id="${memberId}"]`).addClass('selected')
      this.$el.find(`[member-id="${oponentId}"]`).addClass('oponent-selected')
    } else {
      this.$el.find(`[member-id="${memberId}"]`).removeClass('selected')
      this.$el.find(`[member-id="${oponentId}"]`).removeClass('oponent-selected')
    }

    if(this.counters) {
      this.counters.each((i, c) => {
        if(this.totalCount >= this.MAX_TOTAL_COUNT) {
          c.$el.addClass('all-selected')
        } else {
          c.$el.removeClass('all-selected')
        }
      })
    }

    if (this.totalCount >= this.MAX_TOTAL_COUNT) {
      this.$submitBtn.removeAttr('disabled')
    } else {
      this.$submitBtn.attr('disabled', true)
    }
  }

  submit(e) {
    e.preventDefault()
    if(this.isSubmitting === true) return
    this.isSubmitting = true
    const token = $('meta[name="csrf-token"]').attr('content');
    const serialCode = $('#serial-code-input').val()
    this.postData.serial_code = serialCode
    $.ajax({
      type: 'POST',
      url: '/api/v1/votes',
      dataType : 'json',
      headers: {
        'X-CSRF-Token': token
      },
      data: this.postData
    }).done((d) => {
      this.isSubmitting = false
      const html = $('.js-completed-modal').html()
      modal.show(html)
    }).fail((errorEvent) => {
      this.isSubmitting = false
      if(errorEvent.responseJSON && errorEvent.responseJSON.message) {
        this.$errorLabel.text(errorEvent.responseJSON.message)
      } else {
        this.$errorLabel.text('予期せぬエラーが発生しました。')
      }

      const $scrollTarget = $('.js-error-area')
      const body = $('html, body')
      const offset = $scrollTarget.offset()
      const margin = 30
      const pos = offset.top - margin
      body.animate({scrollTop: pos}, 500, 'swing', function(){})
    })
  }
}
