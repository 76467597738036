import $ from 'jquery'
import Charts from './charts'

const renderShareUrl = (result, name, url) => {
  const shareText = `${result.description.replace('アナタ', name + 'さん')}あなたもやってみよう！`
  return `https://twitter.com/intent/tweet?text=${shareText}&url=${url}&hashtags=ビバレン,ビバレンホワイトデー`
}

export default class Xmas2023 {
  constructor() { }

  initialize() {
    this.$el = $('.js-whiteday2024')
    if(this.$el.length <= 0) return
    const charts = new Charts()
    charts.initialize(data, renderShareUrl)
  }

  renderShareUrl
}

const data = {
  questions: [
    [
      {
        title: 'あなたが旅に連れて行きたい動物はどれですか？',
        answers: [
          {
            text: '冒険好きな猿',
            score: 1
          },
          {
            text: '静かな猫',
            score: 2
          },
          {
            text: '元気なウサギ',
            score: 4
          }
        ]
      },
      {
        title: 'あなたは映画を見ています。それはどんな映画？',
        answers: [
          {
            text: 'アクション満載の映画',
            score: 1
          },
          {
            text: 'ロマンチックなラブストーリー',
            score: 2
          },
          {
            text: '謎満載のミステリー映画',
            score: 4
          }
        ]
      },
      {
        title: '夢の中のあなたは、見上げた夜空に何が見える？',
        answers: [
          {
            text: '無数の星が輝く星空',
            score: 1
          },
          {
            text: '美しい月',
            score: 2
          },
          {
            text: '彩り鮮やかなオーロラ',
            score: 4
          }
        ]
      },
    ], [
      {
        title: 'あなたの行きたい休日の過ごし方は？',
        answers: [
          {
            text: '静かな山でハイキング',
            score: 8
          },
          {
            text: '都会のカフェで読書',
            score: 16
          },
          {
            text: '海辺でサーフィン',
            score: 32
          }
        ]
      },
      {
        title: '友人からの突然の訪問、あなたの反応は？',
        answers: [
          {
            text: '歓迎して一緒に楽しい時間を過ごす',
            score: 8
          },
          {
            text: '少し驚くが、準備をして迎え入れる',
            score: 16
          },
          {
            text: '事前に予定がないと不安なので断る',
            score: 32
          }
        ]
      },
      {
        title: 'あなたが一緒に寝たい動物はどれですか？',
        answers: [
          {
            text: 'ふわふわの羊',
            score: 8
          },
          {
            text: 'あたたかい猫',
            score: 16
          },
          {
            text: '小さなハムスター',
            score: 32
          }
        ]
      },
    ], [
      {
        title: '魔法の力を手に入れたら何をしたい？',
        answers: [
          {
            text: '空を飛ぶ',
            score: 64
          },
          {
            text: '時間を止める',
            score: 128
          },
          {
            text: '心を読む',
            score: 256
          }
        ]
      },
      {
        title: '朝起きて外を見たら、奇跡が起きていた！それは次のうちどれ？',
        answers: [
          {
            text: '町中がお菓子の国になっている',
            score: 64
          },
          {
            text: '家の前に金銀財宝が置いてある',
            score: 128
          },
          {
            text: '空飛ぶクルマが飛び交う未来の世界になっていた',
            score: 256
          }
        ]
      },
      {
        title: '星が降ってきた！どうする？',
        answers: [
          {
            text: '星を集めてアクセサリーをつくる',
            score: 64
          },
          {
            text: '星を眺めて願い事をする',
            score: 128
          },
          {
            text: '星を食べる',
            score: 256
          }
        ]
      },
    ]
  ],
  results: [
    {
      scores: [73,138],
      image_url: '',
      name: 'TAIYO',
      description: '明るく優しいものが好きなアナタにはたいようの「あったかもこもこパジャマ」がオススメ！',
      key: 'taiyo'
    }, {
      scores: [76,84],
      image_url: '',
      name: 'SUBARU',
      description: '真面目で慎重派なアナタにはスバルの「キラキラ金平糖ボックス」がオススメ！',
      key: 'subaru'
    }, {
      scores: [161,266],
      image_url: '',
      name: 'ISSEI',
      description: '休憩タイムを愛するアナタにはイッセイの「俺とカフェチケット」がオススメ！',
      key: 'issei'
    }, {
      scores: [82,74],
      image_url: '',
      name: 'MIO',
      description: 'プリンセス気分を楽しみたいアナタにはみおの「ガラスの靴のブリザードフラワー」がオススメ！',
      key: 'mio'
    }, {
      scores: [164,265],
      image_url: '',
      name: 'JINTARO',
      description: 'バスタイムもハッピーに過ごしたいアナタにはじんたろうの「一気にぶちまけろ バスボムボックス」がオススメ！',
      key: 'jintaro'
    }, {
      scores: [81,268],
      image_url: '',
      name: 'CION',
      description: '香りにこだわりたいアナタにはシオンの「選べないからこそ コーヒー紅茶セット」がオススメ！',
      key: 'cion'
    }, {
      scores: [140,289],
      image_url: '',
      name: 'NAGOMU',
      description: '穏やかな時間を大切にしたいアナタにはなごむの「幸せ重ねて バームクーヘン」がオススメ！',
      key: 'nagomu'
    }, {
      scores: [137,146],
      image_url: '',
      name: 'CUC',
      description: '手作りにこだわりたいアナタにはクックの「ぱにゃにゃんチャレンジ手作りパン」がオススメ！',
      key: 'cuc'
    }, {
      scores: [148,274],
      image_url: '',
      name: '39YEAH↗',
      description: 'サプライズ好きなアナタにはさくやの「ドキドキトリプルプレゼントボックス」がオススメ！',
      key: 'sakuya'
    }, {
      scores: [98,290],
      image_url: '',
      name: 'REY',
      description: '美しさ磨きをかけたいアナタにはレイの「最高級・アロマ入りフラワーギフト」がオススメ！',
      key: 'rey'
    }, {
      scores: [162,292],
      image_url: '',
      name: 'AUGURI',
      description: 'キュートで欲張りさんなアナタにはアグリの「ずっと一緒 テディベアとチョコレート」がオススメ！',
      key: 'auguri'
    }, {
      scores: [100],
      image_url: '',
      name: 'FUTABA',
      description: '春が待ち遠しいアナタにはふたばの「お花見楽しみ さくらショコラ」がオススメ！',
      key: 'futaba'
    }, {
      scores: [145,273],
      image_url: '',
      name: 'LION',
      description: '寒さが苦手なアナタにはレオンの「とりあえず巻いとけウールのストール」がオススメ！',
      key: 'lion'
    }, {
      scores: [97,276],
      image_url: '',
      name: 'KAZU',
      description: 'こっそりおしゃれしたいアナタにはかずの「ちょっだけ背のびキラキラブレスレット」がオススメ！',
      key: 'kazu'
    },
  ]
}
