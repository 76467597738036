import $ from 'jquery'

const SCROLL_MARGIN = 30
const DURATION = 1000
class ScrollableLink {
  constructor() {}

  // Usage:
  // <a href="/#top-modal" class="js-scrollable-link" data-link-name="top-modal" data-scroll-margin="200" data-scroll-duration="5000"> Top Modal </a>
  initialize() {
    const $t = $('.js-scrollable-link')
    $t.click((e) => {
      const $target = $(e.currentTarget)
      const name = $target.attr('data-link-name')
      if(!name) return
      const marginData = parseInt($target.attr('data-scroll-margin'))
      const margin = isNaN(marginData) ? SCROLL_MARGIN : marginData

      const durationData = parseInt($target.attr('data-scroll-duration'))
      const duration = isNaN(durationData) ? DURATION : durationData
      const $scrollTarget = $(`[name="${name}"]`)
      if($scrollTarget.length <= 0){
        return
      }
      this.scrollTo(name, margin, duration)
      e.preventDefault()
      e.stopPropagation()
    })
  }

  scrollTo(name, margin = SCROLL_MARGIN, duration = DURATION) {
    const $scrollTarget = $(`[name="${name}"]`)
    if($scrollTarget.length <= 0){
      return
    }
    const body = $('html, body')
    const offset = $scrollTarget.offset()
    const pos = offset.top - margin
    body.animate({scrollTop: pos}, duration, 'swing', function(){})
  }
}
export default new ScrollableLink()
