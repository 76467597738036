import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

class Popup {
  constructor() { }

  initialize() {
    this.class = ''
    this.$content = $('.js-popup-content')
    this.$closeBtn = $('.js-popup-close')
    this.$el = $('.js-popup')
    this.$el.on('click', () => {
      this.hide()
    })
    this.$closeBtn.on('click', () => {
      this.hide()
    })
    this.$content.on('click', (e) => {
      e.stopPropagation()
    })
    this.setModalBtnHandler()
  }

  show(html, className = '') {
    this.$content.html(html)
    this.$el.fadeIn()
    this.additionalClass= className || ''
    this.$el.addClass(this.additionalClass)
    disableBodyScroll(document.querySelector('.js-popup-content'))
  }

  hide(){
    this.$el.fadeOut('normal', () => {
      this.$content.html('')
      clearAllBodyScrollLocks()
      this.$el.removeClass(this.additionalClass)
    })
  }

  setModalBtnHandler() {
    /* USAGE: <a class="js-popup-btn" data-popup-content-selector=".some-content-html" data-popup-class="popup-variant">hoge</a> */
    $('.js-popup-btn').on('click', (e) => {
      const $e = $(e.currentTarget)
      const selector = $e.attr('data-popup-content-selector')
      const className = $e.attr('data-popup-class')
      const html = $(selector).html()
      this.show(html, className)
      e.preventDefault()
      e.stopPropagation()
    })
    this.setYoutubeBtnHandler($('.js-youtube-popup'))
  }

  setYoutubeBtnHandler($el) {
    $el.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      const $e = $(e.currentTarget)
      const youtubeId = $e.attr('data-youtube-id')
      this.show(`
        <div class="youtube">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/${youtubeId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      `)
    })
  }
}

export default new Popup()
